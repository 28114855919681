import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  margin: 30px auto;
  margin-bottom: 50px;
  padding: 0 0 0 0;
  max-width: 736px;
  -webkit-box-shadow: 0px 10px 13px -7px #555555, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #555555, 5px 5px 15px 5px rgba(0,0,0,0);
`;
