import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import BankAccountValidator from 'br-bank-account-validator';

import WarningDanger from 'assets/icons/warning-danger.svg';

import CSeisLogo from 'assets/images/c6.png';
import SafraLogo from 'assets/images/safra.png';
import FactaLogo from 'assets/images/facta.png';
import MERCANTILLogo from 'assets/images/logo-mercantil.jpg';
import ICRED from 'assets/images/icred.png';
import MASTER from 'assets/images/master.png';

import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { ButtonBack } from 'components/Button/styles';

import { FormFooter } from './styles';
import { ISimulationForm } from './types';
import { BankLogo, ConditionItem } from '../../ProposalResult/styles';
import { Title } from '../../QuestionFgts1/styles';

export const ConfirmSimulation = ({
  setWizzardStep,
  step,
}: ISimulationForm) => {
  const { promoter } = usePromoterContext();
  const { form, setForm, handleSubmitDigitation } = useSimulationContext();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const [logoBank, setLogoBank] = useState('');
  useEffect(() => {
    switch (promoter?.promoterStyles.bank) {
      case '626':
        setLogoBank(CSeisLogo);
        break;
      case '149':
        setLogoBank(FactaLogo);
        break;
      case '389':
        setLogoBank(MERCANTILLogo);
        break;
      case '329':
        setLogoBank(ICRED);
        break;
      case '243':
        setLogoBank(MASTER);
        break;
      default:
        setLogoBank(SafraLogo);
        break;
    }
  }, [logoBank]);

  const handleBackWizzard = () => {
    history.push('/innova-fgts/dados-bancarios');
    setWizzardStep(!promoter?.promoterStyles.usePix ? 5 : 9);
  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      handleSubmitDigitation(form);

      setForm({
        ...form,
      });
      history.push('/innova-fgts/sucesso');
      setWizzardStep(6);
    } catch (e) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Confirmar dados
        </Typography>
        <Typography color="default" size={15}>
          Realize a confirmação dos dados informados
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <BankLogo src={logoBank} alt="bank" />
        <ConditionItem>
          <Typography color="default" size={16}>
            Valor para saque
          </Typography>
          <Typography
            id="txt-valor-solicitado"
            color="alert"
            size={40}
            fontWeight={700}
          >
            {form.valueSolicited?.replace('R$ ', '')}
          </Typography>
        </ConditionItem>
        <ConditionItem>
          <Title color="default">Informações Pessoais</Title>
          <Typography color="default" size={16}>
            Nome
          </Typography>
          <Typography id="txt-nome" color="alert" size={22} fontWeight={700}>
            {form.name}
          </Typography>
          <Typography color="default" size={16} marginTop={10}>
            E-mail
          </Typography>
          <Typography id="txt-email" color="alert" size={22} fontWeight={700}>
            {form.email}
          </Typography>
          <Typography color="default" size={16} marginTop={10}>
            Telefone Whatsapp
          </Typography>
          <Typography
            id="txt-whatsapp"
            color="alert"
            size={22}
            fontWeight={700}
          >
            {form.phone}
          </Typography>
          <br />
          <Title color="default">Endereço</Title>
          <Typography color="default" size={16}>
            CEP
          </Typography>
          <Typography id="txt-cep" color="alert" size={22} fontWeight={700}>
            {form.cep}
          </Typography>
          <Typography color="default" size={16} marginTop={10}>
            Endereço
          </Typography>
          <Typography
            id="txt-endereco"
            color="alert"
            size={22}
            fontWeight={700}
          >
            {`${form.address} - ${form.numberAddress}`}
          </Typography>
          <Typography color="default" size={16} marginTop={10}>
            Cidade
          </Typography>
          <Typography id="txt-cidade" color="alert" size={22} fontWeight={700}>
            {`${form.city} - ${form.uf}`}
          </Typography>
          <Typography color="default" size={16} marginTop={10}>
            Bairro
          </Typography>
          <Typography id="txt-bairro" color="alert" size={22} fontWeight={700}>
            {form.district}
          </Typography>
          <Typography
            id="txt-valor-solicitado-hidden"
            color="white"
            size={40}
            fontWeight={700}
          >
            {form.valueSolicited
              ?.replace('R$ ', '')
              .replace('.', '')
              .replace(',', '.')}
          </Typography>
        </ConditionItem>
        <FormFooter>
          <Button
            id="btn-step-10"
            label="Confirmar informações"
            type="submit"
          />
        </FormFooter>
      </Form>

      <ButtonBack
        id="btn-step-10-voltar"
        onClick={handleBackWizzard}
        className="btn btn-success btn-md"
      >
        Voltar
      </ButtonBack>

      <Modal isOpen={isOpen} onCancel={() => setIsOpen(false)}>
        <Flex direction="column" width="100%">
          <img
            src={WarningDanger}
            alt="Aviso!"
            style={{ width: '78px', paddingBottom: '20px', margin: 'auto' }}
          />
          <Flex direction="column" width="100%">
            <Flex
              margin="0 0 20px 0"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="danger" size={16}>
                Atenção, dados bancários incorretos
              </Typography>
            </Flex>
            <Button label="Ok" type="button" onClick={() => setIsOpen(false)} />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
