import { Switch, Route } from 'react-router-dom';

import { Simulation } from 'pages/Simulation';

export const Routes = () => {
  return (
    <Switch>
      <Route path="/:promoter" component={Simulation} />
      <Route path="/:promoter/:fase" component={Simulation} />
    </Switch>
  );
};
