import styled from 'styled-components';

interface IContainer {
  color: 'primary' | 'secondary' | 'default' | 'danger' | 'alert' | 'success' | 'white';
  size?: number;
  fontWeight?: number;
  marginTop?: number;
}

export const Container = styled.p<IContainer>`
  color: ${(props) => props.theme.colors[props.color]};
  font-size: ${(props) => (props.size ? `${props.size}px` : '13px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  line-height: 110%;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : 'auto')};
`;
