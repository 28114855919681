export const banks = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '336', label: 'BANCO C6 S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '756', label: 'BANCO SICOOB' },
  { value: '041', label: 'BANRISUL' },
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '070', label: 'BRB - Banco de Brasília' },
  { value: '037', label: 'Banpara - Banco do estado do Pará' },
  { value: '047', label: 'Banese - Banco do estado do Sergipe' }
];


export const banksSafra = [
  { value: '104', label: 'CAIXA ECONOMICA FEDERAL' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '341', label: 'BANCO ITAÚ S.A.' },
  { value: '033', label: 'BANCO SANTANDER BANESPA S.A.' },
  { value: '756', label: 'BANCO SICOOB' },
  { value: '041', label: 'BANRISUL' },
  { value: '260', label: 'NU BANK PAGAMENTOS S.A.' },
  { value: '077', label: 'BANCO INTER S.A.' },
  { value: '070', label: 'BRB - Banco de Brasília' },
  { value: '037', label: 'Banpara - Banco do estado do Pará' },
  { value: '047', label: 'Banese - Banco do estado do Sergipe' }
];
