import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from 'components/Card';

import { SimulationForm } from './Wizzard/SimulationForm';
import { SimulationResult } from './Wizzard/SimulationResult';
import { PersonalData } from './Wizzard/ProposalForm/PersonalData';
import { AddressData } from './Wizzard/ProposalForm/AddressData';
import { BankData } from './Wizzard/ProposalForm/BankData';
import { ProposalResult } from './Wizzard/ProposalResult';
import { Container } from './styles';
import { QuestionFgts1 } from './Wizzard/QuestionFgts1';
import { QuestionFgts2 } from './Wizzard/QuestionFgts2';
import { ConfirmSimulation } from './Wizzard/ProposalForm/Confirm';
import { BankDataPix } from './Wizzard/BankDataPix';

export const Simulation = () => {

  const params = useParams<any>();
  const [wizzardStep, setWizzardStep] = useState<any>(1);

  useEffect(() => {
    setWizzardStep(params.fase ?? 1);
  }, [])


  const wizzard = () => {
    switch (wizzardStep) {
      case 1:
      case 'simular-proposta':
        return (
          <SimulationForm setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 2:
      case 'resultado-simulacao':
        return (
          <SimulationResult setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 7:
      case 'liberar-saque-aniversario':
        return (
          <QuestionFgts1 setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 8:
      case 'liberar-banco':
        return (
          <QuestionFgts2 setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 3:
      case 'dados-pessoais':
        return (
          <PersonalData setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 4:
      case 'endereco':
        return (
          <AddressData setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 5:
      case 'dados-bancarios':
        return <BankData setWizzardStep={setWizzardStep} step={wizzardStep} />;
      case 6:
      case 'sucesso':
        return (
          <ProposalResult setWizzardStep={setWizzardStep} step={wizzardStep} />
        );
      case 10:
      case 'confirmar-dados':
        return <ConfirmSimulation setWizzardStep={setWizzardStep} step={wizzardStep} />;
      case 9:
          return <BankDataPix setWizzardStep={setWizzardStep} step={wizzardStep} />;
      default:
        setWizzardStep(1)
        break;
    }
  };

  return (
    <Container>
      <Card>{wizzard()}</Card>
    </Container>
  );
};
