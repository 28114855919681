import styled from 'styled-components';

interface IStepsIndicator {
  done: boolean;
}

export const Container = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;

  margin: 0 0 20px 0;
`;

export const StepsIndicator = styled.div<IStepsIndicator>`
  background: ${(props) =>
    props.done ? props.theme.colors.primary : '#e6e6e6'};
  width: 80px;
  height: 10px;
  border-radius: 4px;

  margin-right: 10px;
  margin-bottom: 10px;
`;
