import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetByCep } from 'hooks/places/usePlacesQuery';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { cepMask } from 'utils/masks';
import { ButtonBack } from 'components/Button/styles';
import { usePromoterContext } from 'context/promoter/usePromoterContext';
import Swal from 'sweetalert2';

import { FormFooter } from './styles';
import { ISimulationForm } from './types';

export const AddressData = ({ setWizzardStep, step }: ISimulationForm) => {
  const history = useHistory();
  const { form, setForm } = useSimulationContext();
  const { data } = useGetByCep(form.cep || '');
  const { promoter } = usePromoterContext();

  useEffect(() => {


    console.log(promoter?.promoterStyles)


    if (form.cep?.length === 9) {
      return setForm({
        ...form,
        address: data?.logradouro,
        district: data?.bairro,
        city: data?.localidade,
        uf: data?.uf,
      });
    }
    return setForm({
      ...form,
      address: '',
      district: '',
      city: '',
      uf: '',
    });
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'cep') {
      return setForm({ ...form, cep: cepMask(event.target.value) });
    }
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleContinue = async (event: React.FormEvent) => {

    event.preventDefault();

    if(!form.city || !form.uf){
      
      Swal.fire({
        title: 'O CEP Informado é inválido',
        text: 'Por favor informar um CEP válido',
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      setWizzardStep(!promoter?.promoterStyles.usePix ? 5 : 9);

      return;
    }

    history.push('/innova-fgts/dados-bancarios');
    setWizzardStep(!promoter?.promoterStyles.usePix ? 5 : 9);
  };

  const handleBackWizzard = () => {
    history.push('/innova-fgts/dados-pessoais');
    setWizzardStep(3);
  };

  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Endereço
        </Typography>
        <Typography color="default" size={15}>
          Informe seu endereço para continuar
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <Flex justifyContent="flex-start">
          <Input
            isFullWidth
            id="cep"
            name="cep"
            label="CEP"
            required
            autoFocus
            value={form.cep}
            onChange={handleChange}
            isError={data?.erro}
            errorMessage="CEP inválido"
          />
          <Input
            id="address"
            name="address"
            label="Endereço"
            value={form.address}
            onChange={handleChange}
            margin="0 0 0 20px!important"
            required
            isFullWidth
          />
        </Flex>
        <Flex justifyContent="flex-start">
          <Input
            isFullWidth
            id="numberAddress"
            name="numberAddress"
            label="Número"
            required
            value={form.numberAddress}
            onChange={handleChange}
            margin="10px 0"
          />
          <Input
            id="complementAddress"
            name="complementAddress"
            label="Complemento"
            value={form.complementAddress}
            onChange={handleChange}
            margin="0 0 0 20px!important"
            isFullWidth
          />
        </Flex>
        <Flex>
          <Input
            isFullWidth
            id="district"
            name="district"
            label="Bairro"
            value={form.district}
            required
            onChange={handleChange}
          />
          <Input
            isFullWidth
            id="city"
            name="city"
            label="Cidade"
            value={form.city}
            onChange={handleChange}
            required
            readOnly
            margin="0 0 0 20px!important"
          />
          <Input
            isFullWidth
            id="uf"
            name="uf"
            label="UF"
            value={form.uf}
            onChange={handleChange}
            required
            readOnly
            margin="0 0 0 20px!important"
          />
        </Flex>
        <FormFooter>
          <Button id="btn-step-4" label="Ok, Confirmar endereço" type="submit" />
        </FormFooter>
      </Form>

      <ButtonBack id="btn-step-4-voltar" onClick={handleBackWizzard} className="btn btn-success btn-md">Voltar</ButtonBack>
    </>
  );
};
