import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.button`
  cursor: pointer;
  height: 51px;
  width: 100%;
  font-size: 20px; 

  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: none;
  transition: background 0.3s ease;

  &:disabled {
    cursor: auto;
    opacity: 0.6;
  }

  &:hover {
    background: ${(props) => darken(0.1, props.theme.colors.primary)};
  }
`;

export const Span = styled.span`
  margin-right: 10px;
`;




export const ButtonBack = styled.button`
  background : ${(props) => props.theme.colors.secondary};
  margin-top: 10px;
  cursor: pointer;
  height: 51px;
  width: 100%;
  color: white;
  border-radius: 4px;
  border: none;
  text-align: center;
`;
