
import { useHistory } from 'react-router-dom';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Typography } from 'components/Typography';
import Select from 'components/Form/Select';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { statesList } from 'utils/statesList';
import { dateMask, moneyMask } from 'utils/masks';

import { FormFooter } from './styles';
import { ISimulationForm } from './types';

export const PersonalData = ({ setWizzardStep, step }: ISimulationForm) => {
  const { form, setForm } = useSimulationContext();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'valueIncome') {
      return setForm({
        ...form,
        [event.target.name]: moneyMask(event.target.value),
      });
    }


    if (event.target.name === 'dateEmissionRg') {      
      return setForm({
        ...form,
        [event.target.name]: dateMask(event.target.value),
      });
    }
    

    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });

  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();

    history.push('/innova-fgts/endereco');
    setWizzardStep(4);
  };

  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Dados Pessoais
        </Typography>
        <Typography color="default" size={15}>
          Informe seus dados pessoais para continuar
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <Input
          id="name"
          name="name"
          label="Nome Completo"
          required
          autoFocus
          value={form.name}
          onChange={handleChange}
          placeholder="Informe seu nome completo"
        />
        <Input
          isFullWidth
          id="email"
          name="email"
          label="E-mail"
          type="email"
          value={form.email}
          onChange={handleChange}
          margin="0 5px 0 0"
          placeholder="Informe seu e-mail"
        />
        <Flex margin="10px 0 0 0">
          <Input
            isFullWidth
            id="birthDate"
            name="birthDate"
            label="Data de Nascimento"
            type="text"
            required
            value={form.birthDate}
            onChange={handleChange}
            margin="0 5px 0 0"
            readOnly
          />
          <Input
            isFullWidth
            id="phone"
            name="phone"
            label="Celular"
            required
            value={form.phone}
            onChange={handleChange}
            readOnly
            margin="0 0 0 5px!important"
          />
        </Flex>
        <Flex margin="10px 0 0 0">
          <Select
            label="Sexo"
            required
            name="sex"
            id="sex"
            value={form.sex || ''}
            onChange={(e) => setForm({ ...form, sex: e.target.value })}
            options={[
              { value: 'M', label: 'Masculino' },
              { value: 'F', label: 'Feminino' },
            ]}
            margin="0 5px 0 0"
          />
          <Select
            label="Estado Civil"
            required
            name="stateCivil"
            id="stateCivil"
            value={form.stateCivil || ''}
            onChange={(e) => setForm({ ...form, stateCivil: e.target.value })}
            options={[
              'Casado',
              'Divorciado',
              'Separado Judicialmente',
              'Solteiro',
              'União Estável',
              'Viúvo',
            ]}
            margin="0 0 0 5px!important"
          />
        </Flex>
        <Flex margin="10px 0 0 0">
          <Input
            isFullWidth
            id="nameMother"
            name="nameMother"
            label="Nome da Mãe"
            required
            value={form.nameMother}
            onChange={handleChange}
            placeholder="Qual é o nome da sua mãe"
          />
        </Flex>
        <Flex margin="10px 0 0 0">
          <Input
            isFullWidth
            id="rg"
            name="rg"
            label="RG"
            required
            value={form.rg}
            onChange={handleChange}
            margin="0 5px 0 0"
            placeholder="Qual é o nº do seu RG"
          />
          <Input
            isFullWidth
            id="dateEmissionRg"
            name="dateEmissionRg"
            label="Data de Emissão"
            type="text"
            required
            value={form.dateEmissionRg}
            onChange={handleChange}
            placeholder="--/--/----"
            margin="0 0 0 5px!important"
          />
        </Flex>
        <Flex margin="10px 0 0 0">
          <Select
            label="Estado"
            required
            name="ufRg"
            id="ufRg"
            value={form.ufRg || ''}
            onChange={(e) => setForm({ ...form, ufRg: e.target.value })}
            options={statesList}
            margin="0 5px 0 0"
          />
          <Input
            isFullWidth
            id="emissionRG"
            name="emissionRG"
            label="Órgão Emissor"
            required
            value={form.emissionRG}
            onChange={handleChange}
            margin="0 0 0 5px!important"
            placeholder="Ex: SSP/PR"
          />
        </Flex>
        <FormFooter>
          <Button id="btn-step-3" label="Ok, Confirmar dados pessoais" type="submit" />
        </FormFooter>
      </Form>
    </>
  );
};
