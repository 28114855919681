import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';

import { ValidURL } from 'components/ValidURL';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Container } from 'components/Container';
import { queryClient } from 'services/queryClient';
import { SimulationProvider } from 'context/simulation/useSimulationContext';
import { PromoterProvider } from 'context/promoter/usePromoterContext';

import { Routes } from 'routes';
import { Theme } from 'styles';
import { Questions } from 'pages/Questions';

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PromoterProvider>
        <Router>
          <Route path="/:promoter/duvidas" component={Questions} />
          <ValidURL>
            <SimulationProvider>
              <Theme>
                <Container>
                  <Header />
                  <Routes />
                  <Footer />
                </Container>
              </Theme>
            </SimulationProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ fontSize: '13px' }}
              theme="colored"
            />
            <ReactQueryDevtools />
          </ValidURL>
        </Router>
      </PromoterProvider>
    </QueryClientProvider>
  );
};
