/* eslint-disable */
import { useState } from 'react';
// import BankAccountValidator from 'br-bank-account-validator';

import WarningDanger from 'assets/icons/warning-danger.svg';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
// import { ISimulationData } from 'context/simulation/types';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { ButtonBack } from 'components/Button/styles';

import { FormFooter } from './styles';
import { ISimulationForm } from './types';
import Select from 'components/Form/Select';
import { cpfMask, phoneMask } from 'utils/masks';
import {cpf} from 'cpf-cnpj-validator';
import Swal from 'sweetalert2';

export const BankDataPix = ({ setWizzardStep, step }: ISimulationForm) => {
  const { form, setForm } = useSimulationContext();
  const [isOpen, setIsOpen] = useState(false);
  const [typePix, setTypePix] = useState('');


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    let value = event.target.value

    if(typePix === '1'){
      value = cpfMask(value);
    }

    if(typePix === '2'){
      value = phoneMask(value);
    }

    if(event.target.id === 'pix'){
      setForm({
        ...form,
        [event.target.name]: value,
      });
    }
  };

  const handleBackWizzard = () => {
    setWizzardStep(4);
  }

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    try {

      if(typePix === '1'){
        if (!cpf.isValid(form.pix ?? '')) {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'O CPF informado é inválido'
          })
          return;
        }
      }
      
      let  pix = form.pix;

      if(pix){
        if(typePix === '1' || typePix === '2'){
          pix = pix.replace(/[^0-9]/g, '')
        }

        if(typePix === '2'){  
          pix = '+55' + pix
        }
      }

    setForm({
      ...form,
      pix: pix
    });
      setWizzardStep(10);
    } catch (e) {
      setIsOpen(true);
    }

  };
  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Dados Bancários
        </Typography>
        <Typography color="default" size={15}>
          Informe seus dados bancários para continuar
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <Select
          id="typePix"
          name="typePix"
          label="Tipo de chave PIX"
          value={typePix}
          onChange={(e) => {
            if(e.target.name === 'typePix'){
              setTypePix(e.target.value)
            }
          }}
          required
          options={[
            { value: '1', label: 'CPF' },
            { value: '2', label: 'Celular' },
            { value: '3', label: 'E-mail' },
            { value: '4', label: 'Aleatória' },
          ]}
        />
        <Input
          isFullWidth
          id="pix"
          name="pix"
          label="PIX"
          placeholder='Informe seu PIX aqui, E-mail, Celular, CPF ou Chave aleatória'
          required
          value={form.pix}
          onChange={handleChange}
        />
        <FormFooter>
          <Button id='btn-step-9' label="Confirmação" type="submit" />
        </FormFooter>
      </Form>

      <ButtonBack 
      id='btn-step-9-voltar'
      onClick={handleBackWizzard} 
      className="btn btn-success btn-md">Voltar</ButtonBack>

      <Modal isOpen={isOpen} onCancel={() => setIsOpen(false)}>
        <Flex direction="column" width="100%">
          <img
            src={WarningDanger}
            alt="Aviso!"
            style={{ width: '78px', paddingBottom: '20px', margin: 'auto' }}
          />
          <Flex direction="column" width="100%">
            <Flex
              margin="0 0 20px 0"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="danger" size={16}>
                Atenção, dados bancários incorretos
              </Typography>
            </Flex>
            <Button label="Ok" type="button" onClick={() => setIsOpen(false)} />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
