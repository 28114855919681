/* eslint-disable */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import CSeisLogo from 'assets/images/c6.png';
import SafraLogo from 'assets/images/safra.png';
import FactaLogo from 'assets/images/facta.png';
import MERCANTILLogo from 'assets/images/logo-mercantil.jpg';
import ICRED from 'assets/images/icred.png';
import MASTER from 'assets/images/master.png';

import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { currencyFormat } from 'utils/currencyFormat';
import { moneyMask } from 'utils/masks';

import {
  Container,
  Conditions,
  ConditionItem,
  FormFooter,
  BankLogo,
} from './styles';
import { ISimulationResult } from './types';

export const SimulationResult = ({
  setWizzardStep,
  step,
}: ISimulationResult) => {
  const history = useHistory();
  const { create, setForm, form } = useSimulationContext();
  const { promoter } = usePromoterContext();
  const [value, setValue] = useState(0);
  const [validValue, setValidValue] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [logoBank, setLogoBank] = useState('');
  useEffect(() => {
    console.log(promoter?.promoterStyles.bank);
    switch (promoter?.promoterStyles.bank) {
      case '626':
        setLogoBank(CSeisLogo);
        break;
      case '149':
        setLogoBank(FactaLogo);
        break;
      case '389':
        setLogoBank(MERCANTILLogo);
        break;
      case '329':
        setLogoBank(ICRED);
        break;
      case '243':
        setLogoBank(MASTER);
        break;
      default:
        setLogoBank(SafraLogo);
        break;
    }
  }, [logoBank]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: moneyMask(event.target.value),
    });
    setValue(Number(event.target.value.replace(/\D/g, '')) / 100);
    setValidValue(true);
  };

  const handleContinue = () => {
    let valueSolicited = value;
    const febrabanBank = create.data?.data?.result?.febrabanId;

    if (febrabanBank !== '626') {
      valueSolicited = Number(create.data?.data?.result?.totalCreditLiberty);
      setValue(valueSolicited);
    }

    // if (valueSolicited < 200) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Erro',
    //     text : 'O valor solicitado não pode ser menor que R$ 300,00'
    //   });
    //   setErrorMessage('O valor solicitado não pode ser menor que R$ 300,00');
    //   return setValidValue(false);
    // }
    if (Number(create?.data?.data?.result?.totalCreditLiberty) < value) {
      const valida = create?.data?.data?.result?.totalCreditLiberty;
      setErrorMessage(`O valor solicitado não pode ser superior a ${valida}`);
      return setValidValue(false);
    }
    if (febrabanBank !== '626') {
      setForm({
        ...form,
        idSimulation: create.data?.data?.result?.idSimulation,
        valueSolicited: create.data?.data?.result?.totalCreditLiberty,
      });
    } else {
      setForm({
        ...form,
        idSimulation: create.data?.data?.result?.idSimulation,
      });
    }

    history.push('/innova-fgts/dados-pessoais');
    setWizzardStep(3);
  };

  if (create.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center" noWrap>
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={26} fontWeight={700}>
              Realizando Simulação
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  let ErrorMessage = '';
  
  if (create.status === 'error') {
    ErrorMessage = create?.error?.response?.data?.message
      .replace(/[^a-zA-Zçã ]/g, '')
      .trim();
    switch (ErrorMessage) {
      case 'Trabalhador não possui adesão ao saque aniversário vigente na data corrente':
        history.push('/innova-fgts/liberar-saque-aniversario');
        setWizzardStep(7);
        break;
      case 'Instituição Fiduciria não possui autorização do Trabalhador para Operação Fiduciria':
        history.push('/innova-fgts/liberar-banco');
        setWizzardStep(8);
        break;
      default:
        history.push('/innova-fgts/simular-proposta');
        setWizzardStep(1);
        break;
    }
  }

  const content = () => {
    return (
      <Container>
        <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
          <Typography color="default" size={26} fontWeight={700}>
            Proposta
          </Typography>
          <Typography color="default" size={15}>
            Peça agora seu adiantamento, confira as condições
          </Typography>
        </Flex>
        <WizzardSteps steps={6} step={step} />
        <BankLogo src={logoBank} alt="bank" />
        <Conditions>
          <ConditionItem>
            <Typography
              color="default"
              size={38}
              style={{ color: '#656565 !important' }}
            >
              <strong>Valor liberado</strong> em até
            </Typography>
            <Typography
              color="default"
              size={38}
              style={{ color: '#656565 !important', marginBottom: '10px' }}
            >
              <strong>20 min</strong>. no Pix:
            </Typography>

            <Typography
              id="txt-total-liberty"
              color="alert"
              size={60}
              fontWeight={700}
            >
              {currencyFormat(create.data?.data?.result?.totalCreditLiberty)}
            </Typography>
          </ConditionItem>
        </Conditions>
        {create.data?.data?.result?.febrabanId === '626' && (
          <Flex margin="20px 0 0 0">
            <Form onSubmit={handleContinue}>
              <Input
                id="valueSolicited"
                name="valueSolicited"
                label="Quanto deseja sacar?"
                required
                autoFocus
                value={form.valueSolicited}
                onChange={handleChange}
                isError={!validValue}
                errorMessage={errorMessage}
              />
            </Form>
          </Flex>
        )}
        <FormFooter>
          <Button
            id="btn-step-2"
            type="button"
            label="Receber agora"
            style={{
              background: '#4ba62f !important',
              height: 70,
              fontSize: '22px',
            }}
            onClick={handleContinue}
          />
        </FormFooter>
      </Container>
    );
  };

  return content();
};
