import { usePromoterContext } from 'context/promoter/usePromoterContext';

export const useCustomerTheme = () => {
  const { promoter } = usePromoterContext();

  const defaultTheme = {
    colors: {
      default: '#666666',
      primary: '#4ebed8',
      secondary: '#e4bd37',
      danger: 'red',
      white: '#ffffff',
      alert: 'orange',
    },
    background: 'https://i.imgur.com/7Hlt5bH.png',
    homeImage: 'https://i.imgur.com/hgURlo6.png',
    bgColorPrimary: '#4ebed8',
    bgColorSecondary: '#e4bd37'
  };

  const customerTheme = {
    colors: {
      white: '#ffffff',
      alert: promoter?.promoterStyles?.colorAlert || defaultTheme.colors.alert,
      danger:
        promoter?.promoterStyles?.colorDanger || defaultTheme.colors.danger,
      default:
        promoter?.promoterStyles?.colorDefault || defaultTheme.colors.default,
      primary:
        promoter?.promoterStyles?.colorPrimary || defaultTheme.colors.primary,
      secondary:
        promoter?.promoterStyles?.colorSecondary || defaultTheme.colors.secondary,
      success: '#42C8B6',
    },
    logo: promoter?.promoterStyles?.logo,
    background: promoter?.promoterStyles?.background || defaultTheme.background,
    homeImage: promoter?.promoterStyles?.homeImage || defaultTheme.homeImage,
    document : promoter?.promoterStyles?.document || null,
    corporativeName : promoter?.promoterStyles?.corporativeName || '',
    contactMovelPhone : promoter?.promoterStyles?.contactMovelPhone || null,
    messageContactWhatsapp : promoter?.promoterStyles?.messageContactWhatsapp || 'Olá gostaria de tirar uma duvida!',
    bgColorPrimary: promoter?.promoterStyles?.bgColorPrimary || '#4ebed8',
    bgColorSecondary: promoter?.promoterStyles?.bgColorSecondary || '#4ebed8'
  };

  return customerTheme;
};
