/* eslint-disable */
import { Container } from "components/Container";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { usePromoterContext } from "context/promoter/usePromoterContext";
import { useGetPromoter } from "hooks/promoter/usePromoterQuery";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "styles/GlobalStyle";
import { useCustomerTheme } from "styles/useCustomerTheme";
import { ContainerFluid, H1, SubTitle, Table, Text } from "./styles";

export interface Params {
  promoter : string
}


export const Questions = () => {
  const { setPromoter } = usePromoterContext();
  const {promoter} = useParams<Params>();
  const { data } = useGetPromoter(`/${promoter}`);
  const customerTheme = useCustomerTheme();
  const GlobalStyleTheme = GlobalStyle();

  useEffect(() => {
    setPromoter(data || null);
  }, [data]);

  
  return (
    <>
      <ThemeProvider theme={customerTheme}>
        <GlobalStyleTheme />
        <Container>
          <Header />
          <ContainerFluid>
            <a href="javascript:history.go(-1)">Voltar</a>
            <H1>Perguntas Frequentes - Antecipação Saque Aniversário FGTS</H1>
            <p>​Tire suas dúvidas sobre essa modalidade de crédito</p>
            <SubTitle>​O que é a Antecipação Saque Aniversário FGTS?</SubTitle>
            <Text>
              É um empréstimo que permite a antecipação do valor de até 3 (três) parcelas de Saque Aniversário do 
              FGTS às quais o cliente tem direito de sacar anualmente no mês do seu aniversário.<br /><br />

              Para ter direito ao Saque Aniversário, é necessário que o trabalhador faça a opção pela modalidade 
              Saque Aniversário através do APP FGTS, no site fgts.caixa.gov.br, no Internet Banking CAIXA ou nas Agências.<br /><br />

              O trabalhador que fizer a opção poderá sacar um percentual do saldo do FGTS, acrescido de uma parcela 
              adicional, anualmente, conforme tabela abaixo:
            </Text>
            <Table cellSpacing="10">
              <tr>
                <td><strong>Limite Saldo</strong></td>
                <td><strong>Alíquota</strong></td>
                <td><strong>Parcela Adicional</strong></td>
              </tr>
              <tr>
                <td>Até R$ 500,00​</td>
                <td>50%</td>
                <td>-</td>
              </tr>
              <tr>
                <td>De R$ 500,01 até R$ 1.000,00​</td>
                <td>40%</td>
                <td>R$ 50,00</td>
              </tr>
              <tr>
                <td>De R$ 1.000,01 até R$ 5.000,00​</td>
                <td>30%%</td>
                <td>R$ 150,00</td>
              </tr>
              <tr>
                <td>De R$ 5.000,01 até R$ 10.000,00​</td>
                <td>20%</td>
                <td>R$ 650,00</td>
              </tr>
              <tr>
                <td>De R$ 10.000,01 até R$ 15.000,00</td>
                <td>15%</td>
                <td>R$ 1.150,00</td>
              </tr>
              <tr>
                <td>De R$ 15.000,01 até R$ 20.000,00​</td>
                <td>10%</td>
                <td>R$ 1.900,00</td>
              </tr>
              <tr>
                <td>Acima de R$ 20.000,01​</td>
                <td>5%</td>
                <td>R$ 2.900,00</td>
              </tr>
            </Table>

            
            <SubTitle>Quem pode contratar a Antecipação do Saque Aniversário?</SubTitle>
            <Text>
              Todo cliente com conta vinculada de FGTS, ativa ou inativa, que tenha aderido à modalidade Saque Aniversário através de um dos canais disponibilizados pela CAIXA, possua conta na CAIXA (poupança ou conta corrente) e não possua empréstimo em atraso com a CAIXA.
            </Text>
            
            <SubTitle>Quais vantagens em contratar esse empréstimo de Antecipação do Saque Aniversário FGTS?</SubTitle>
            <Text>
              • Rapidez e facilidade: acesso imediato ao recurso, sem esperar a data do Saque Aniversário;<br />
              • Uma das menores taxas de juros em produtos de crédito comercial;<br />
              • Possibilidade de antecipação até 3 (três) parcelas anuais de Saque Aniversário FGTS;<br />
              • Sem prestação mensal, assim você não compromete o seu orçamento mensal com pagamento de parcelas;<br />
              • O pagamento da dívida ocorre com o valor do seu Saque Aniversário FGTS, na data da parcela anual prevista para a modalidade;<br />
              • Não há necessidade de aval, ou de avaliação de risco de crédito.              
            </Text>
            
            <SubTitle>​Como optar pela Modalidade de Saque Aniversário FGTS?</SubTitle>
            <Text>
              O cliente poderá optar pelo Saque Aniversário, pelos seguintes canais:<br />
              • Site do FGTS na CAIXA, <a href="https://www.caixa.gov.br/beneficios-trabalhador/fgts/Paginas/default.aspx" target="_blank">fgts.caixa.gov.br</a>; ou<br />
              • Aplicativo do FGTS, que você pode baixar na loja de aplicativos do seu celular); ou<br />
              • No Internet Banking CAIXA.
            </Text>
            <SubTitle>Atenção! ​Você pode voltar para o saque-rescisão?</SubTitle>
            <Text>
              Quem migrar para o Saque-Aniversário e decidir voltar à sistemática Saque-Rescisão poderá solicitar a reversão a qualquer momento. 
              A alteração surtirá efeito no <strong>1º dia do 25º mês da solicitação</strong>.<br /><br />
              Aos optantes pelo Saque-Aniversário, é permitida a movimentação da conta do FGTS nas hipóteses previstas em Lei, 
              como para moradia própria, doenças graves, aposentadoria, calamidade pública e outros, excetuando-se os 
              casos em que ocorrer demissão sem justa causa, rescisão por culpa recíproca ou força maior, rescisão em comum 
              acordo entre o trabalhador e empregador, extinção do contrato de trabalho a termo e temporário, falecimento 
              do empregador individual, falência da empresa ou nulidade de contrato e suspensão do trabalho avulso. 
              Nestes casos, é garantido ao trabalhador o saque da multa rescisória, quando devida.
            </Text>
            
            <br />

            <Text>fonte: <a href="https://www.caixa.gov.br/voce/credito-financiamento/credito-pessoal/antecipacao-saque-aniversario-FGTS/perguntas-frequentes/Paginas/default.aspx" target="_blank">caixa.gov.br</a></Text>
          </ContainerFluid>
          <Footer />
        </Container>
      </ThemeProvider>
    </>
  )
}