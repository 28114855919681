import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useGetPromoter } from 'hooks/promoter/usePromoterQuery';
import { usePromoterContext } from 'context/promoter/usePromoterContext';

export const ValidURL = ({ children }: any) => {
  const { setPromoter } = usePromoterContext();
  const history = useHistory();
  const { data } = useGetPromoter('/innova-fgts');
  
  useEffect(() => {
    console.log(data)
    setPromoter(data || null);
    if(data){
      history.push('/innova-fgts/simular-proposta');
    }
  }, [data]);
  

  return <>{children}</>;
};
