/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface IContainer {
  isError?: boolean;
  isFullWidth?: boolean;
  margin?: string;
}

export const Container = styled.div<IContainer>`
  ${(props) =>
    props.isFullWidth &&
    css`
      width: 100%;
    `}

  & + & {
    margin-top: 10px;
  }

  margin: ${(props) => props.margin ? `${props.margin}` : '0'};

  @media (max-width: 500px) {
    margin: 10px 0 0 0 !important;
  }  

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    height: 42px;
    border: 1px solid #e6e6e6;
    background: none;
    width: 100%;
    padding: 0 18px;
    border-radius: 4px;

    ${(props) =>
    props.isError &&
    css`
        border: 1px solid red;
      `};

    &:focus {
        outline: none;
        box-shadow: 0px 0px 3px #48A3F2;
        border: 1px solid #48A3F2;
    }

    &:read-only {
      background-color: #f3f3f3;
    }

    ::placeholder {
      opacity: 0.4;
    }
      
  }

  small {
    color: ${(props) => props.theme.colors.danger};
    display: block;
  }
`;
