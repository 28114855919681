import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import BankAccountValidator from 'br-bank-account-validator';

import WarningDanger from 'assets/icons/warning-danger.svg';

import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
// import { ISimulationData } from 'context/simulation/types';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Typography } from 'components/Typography';
import Select from 'components/Form/Select';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { banks, banksSafra } from 'utils/banks';
import { ButtonBack } from 'components/Button/styles';

import { FormFooter } from './styles';
import { ISimulationForm } from './types';

export const BankData = ({ setWizzardStep, step }: ISimulationForm) => {
  const history = useHistory();
  const { promoter } = usePromoterContext();
  const { form, setForm } = useSimulationContext();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleBackWizzard = () => {
    history.push('/innova-fgts/endereco');
    setWizzardStep(4);
  }

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    history.push('/innova-fgts/confirmar-dados');
    setWizzardStep(10);
  };
  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Dados Bancários
        </Typography>
        <Typography color="default" size={15}>
          Informe seus dados bancários para continuar
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <Select
          id="bank"
          name="bank"
          label="Banco"
          autoFocus
          value={form.bank || ''}
          onChange={(e) => setForm({ ...form, bank: e.target.value })}
          options={promoter?.promoterStyles.bank === "626" ? banks : banksSafra}
        />
        <Flex>
          <Input
            isFullWidth
            id="agencyBank"
            name="agencyBank"
            label="Agência"
            required
            value={form.agencyBank}
            onChange={handleChange}
            maxLength={4}
          />
          <Input
            isFullWidth
            id="numberAccountBank"
            name="numberAccountBank"
            label="Conta"
            required
            value={form.numberAccountBank}
            onChange={handleChange}
            margin="0 0 0 20px!important"
          />
          <Input
            isFullWidth
            id="digitAccountBank"
            name="digitAccountBank"
            label="Digito"
            required
            value={form.digitAccountBank}
            onChange={handleChange}
            margin="0 0 0 20px!important"
          />
        </Flex>
        <Select
          id="typeAccount"
          name="typeAccount"
          label="Tipo da Conta"
          value={form.typeAccount || ''}
          onChange={(e) => setForm({ ...form, typeAccount: e.target.value })}
          options={[
            { value: '01', label: 'Conta Corrente' },
            { value: '02', label: 'Conta Poupança' },
          ]}
        />
        <FormFooter>
          <Button id="btn-step-5" label="Finalizar" type="submit" />
        </FormFooter>
      </Form>

      <ButtonBack id="btn-step-5-voltar" onClick={handleBackWizzard} className="btn btn-success btn-md">Voltar</ButtonBack>

      <Modal isOpen={isOpen} onCancel={() => setIsOpen(false)}>
        <Flex direction="column" width="100%">
          <img
            src={WarningDanger}
            alt="Aviso!"
            style={{ width: '78px', paddingBottom: '20px', margin: 'auto' }}
          />
          <Flex direction="column" width="100%">
            <Flex
              margin="0 0 20px 0"
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="danger" size={16}>
                Atenção, dados bancários incorretos
              </Typography>
            </Flex>
            <Button label="Ok" type="button" onClick={() => setIsOpen(false)} />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
