import styled from "styled-components";

export const ContainerFluid = styled.div`
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
`;

export const H1 = styled.h1`
`;

export const SubTitle = styled.h2`
  margin-top: 30px;
`;
export const Text = styled.p`
  line-height: 25px;
`;

export const Table = styled.table`
  
`;