// import { Link } from 'react-router-dom';

import WhatsAppLogo from 'assets/images/whatsapp.png';
import { Link, useLocation } from 'react-router-dom';

import { useCustomerTheme } from 'styles/useCustomerTheme';

import { Container, Logo, Nav, ContactButton } from './styles';

export const Header = () => {
  const customerTheme = useCustomerTheme();
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const urlAtual = `/${pathArray[1]}/duvidas`;
  const url = urlAtual === pathname ? pathname : `${pathArray[1]}/duvidas`;

  return (
    <>
      <meta
        name="description"
        content={`Simulador FGTS ${customerTheme.corporativeName}`}
      />
      <title>{`Simulador FGTS - ${customerTheme.corporativeName}`}</title>
      <Container>
        <Logo src={customerTheme.logo} alt="logo" />
        <Nav>
          <Link to={url}>Dúvidas Frequentes</Link>
          <ContactButton href={`https://api.whatsapp.com/send?phone=55${customerTheme.contactMovelPhone}&text=${customerTheme.messageContactWhatsapp}`} target="_blank">
            <img src={WhatsAppLogo} alt="contact" />
            <p>Atendimento</p>
          </ContactButton>
        </Nav>
      </Container>
    </>
  );
};
