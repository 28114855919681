import { useCustomerTheme } from 'styles/useCustomerTheme';
import { Container } from './styles';

export const Footer = () => {
  const customerTheme = useCustomerTheme();

  const date = new Date();

  return (
    <>
      <Container>
        <p>{`© ${customerTheme.corporativeName} - ${date.getFullYear()}`}</p>
        <p>{`CNPJ: ${customerTheme.document}`}</p>
      </Container>
    </>
  );
};
