import { useQuery } from 'react-query';
import api from 'services/api';

import { PromoterProps } from './types';

export const useGetPromoter = (pathname: string) => {
  const getPromoter = useQuery('promoter', async () => {
    const { data } = await api.get<PromoterProps>(
      `/v1/promoter-styles${pathname}`
    );
    return data;
  },
  { refetchOnWindowFocus: false });

  return getPromoter;
};
