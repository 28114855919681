import { useState } from 'react';
import { cpf } from 'cpf-cnpj-validator';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { Button } from 'components/Button';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { phoneMask, cpfMask, formataStringData, dateMask } from 'utils/masks';
import { WizzardSteps } from 'components/WizzardSteps';
import { Flex } from 'components/Flex';
import { Typography } from 'components/Typography';

import { Checkbox } from 'components/Form/Checkbox';
import { FormFooter } from './styles';
import { ISimulationForm } from './types';

export const SimulationForm = ({ setWizzardStep, step }: ISimulationForm) => {
  const { handleSimulate, form, setForm } = useSimulationContext();
  const history = useHistory();
  const { promoter } = usePromoterContext();
  const [validCpf, setValidCpf] = useState<boolean>(true);
  const [validPhone, setValidPhone] = useState<boolean>(true);
  const [validDate, setValidDate] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'cpf') {
      setForm({ ...form, cpf: cpfMask(event.target.value) });
      return setValidCpf(true);
    }


    if (event.target.name === 'phone') {
      setForm({
        ...form,
        [event.target.name]: phoneMask(event.target.value),
      });

      if(event.target.value.replace(/[^0-9]/g, '').length < 11){
        return setValidPhone(false);
      }

      return setValidPhone(true);
    }

    if (event.target.name === 'name') {
      setForm({
        ...form,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === 'birthDate') {
      setForm({ ...form, birthDate: dateMask(event.target.value) })
      return setValidDate(true);
    }
  };


  const setTokenCaptcha = async (tokenCaptcha : any) => {
    if(tokenCaptcha){
      setForm({
        ...form,
        token : tokenCaptcha,
      });
    }
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    return setForm({
      ...form,
      [event.target.name]: event.target.checked,
    });
  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();

    if(!form.token){
      Swal.fire({
        title : 'Erro',
        text: 'Você precisa selecionar o captcha',
        icon: 'warning'
      })
      return;
    }
    
    const today = dayjs(new Date())
    const dateFormated = dayjs(formataStringData(form.birthDate.replace(/[/]/g,'-')));
    const age = dayjs(dateFormated)
    const year = today.diff(age, 'year');
    
    if(year > 100 || !dayjs(dateFormated).isValid() || dayjs(dateFormated).year() < 1940){
      setValidDate(false)
      return;
    }


    if (!cpf.isValid(form.cpf) || form.phone.length < 14) {
      setValidCpf(cpf.isValid(form.cpf));
      setValidPhone(form.phone.length >= 14);
      return;
    }

    const data = {
      id: promoter?.promoterStyles.id as string,
      cpf: form.cpf,
      phone: form.phone,
      birthDate: form.birthDate,
      name: form.name,
      token : form.token
    };
    
    handleSimulate(data);
    history.push('/innova-fgts/resultado-simulacao');
    setWizzardStep(2);  
  };

  const isDisabled =
    form?.cpf !== '' &&
    form.cpf?.length === 14 &&
    form.phone.length >= 14 &&
    // form.acceptTerms === true &&
    form.personalData === true &&
    form.allowContact === true &&
    form.token;

  return (
    <>
      <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
        <Typography color="default" size={26} fontWeight={700}>
          Simulação
        </Typography>
        <Typography color="default" size={15}>
          Para continuar nos informe os dados abaixo:
        </Typography>
      </Flex>
      <WizzardSteps steps={6} step={step} />
      <Form onSubmit={handleContinue}>
        <Input
          id="name"
          name="name"
          label="Nome Completo"
          required
          autoFocus
          onChange={handleChange}
          placeholder="Informe seu nome completo"
        />
        <Input
          id="birthDate"
          name="birthDate"
          label="Data de Nascimento"
          type="text"
          required
          placeholder="--/--/----"
          value={form.birthDate}
          onChange={handleChange}
          isError={!validDate}
          errorMessage="Data informada é inválida"
        />
        <Input
          id="cpf"
          name="cpf"
          label="CPF"
          placeholder="EX: 000.000.000-00"
          required
          value={form.cpf}
          onChange={handleChange}
          isError={!validCpf}
          errorMessage="CPF inválido"
        />
        <Input
          id="phone"
          name="phone"
          label="Celular WhatsApp"
          placeholder="EX: (00) 0 0000-0000"
          required
          value={form.phone}
          onChange={handleChange}
          isError={!validPhone}
          errorMessage="Celular inválido, O celular deve conter 11 digitos"
        />
        {/* <Checkbox
          id="acceptTerms"
          name="acceptTerms"
          label="Concordo com os termos de uso"
          value={String(form.acceptTerms)}
          onChange={handleCheck}
          margin="20px 0 0 0"
        /> */}
        <Checkbox
          id="personalData"
          name="personalData"
          label="Concordo em fornecer meus dados pessoais para consulta"
          value={String(form.personalData)}
          onChange={handleCheck}
          margin="20px 0 0 0"
        />
        <Checkbox
          id="allowContact"
          name="allowContact"
          label="Permitir que entre em contato por whatsapp"
          value={String(form.allowContact)}
          onChange={handleCheck}
        />        
        <ReCAPTCHA
          sitekey="6LczAu4dAAAAANxkvoTPgkhuC7HfowUwvxZMO8dV"
          onChange={setTokenCaptcha}
        />
        <FormFooter>
          <Button id="btn-step-1" label="Ok, Quero simular" type="submit" disabled={!isDisabled} />
        </FormFooter>
      </Form>
    </>
  );
};
