import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px;

  background: ${(props) => props.theme.colors.white};
  height: 80px;
  padding: 0 40px;

  @media (max-width: 500px) {
    padding: 20px;
  }

  @media (max-width: 1280px) {
    padding: 40px;
  }
`;

export const ContainerHidden = styled.div`
  height: 80px;
`;

export const Logo = styled.img`
  height: 60px;

  @media (max-width: 500px) {
    height: 40px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: ${(props) => props.theme.colors.default};
  }

  a + a {
    margin-left: 20px;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    a {
      font-size: 13px;
    }

    a + a {
      margin: 3px 0 0 0;
    }
  }
`;

export const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  border: 2px solid #17b068;
  border-radius: 29px;
  padding: 10px 20px;
  cursor: pointer;

  @media (max-width: 500px) {
    padding: 5px 10px;
  }

  img {
    width: 28px;
  }

  p {
    color: #17b068;
    font-weight: 600;
  }
`;
