/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import fgts2 from 'assets/images/fgts2.jpg';
import Warning from 'assets/images/warning.png';
import GooglePlay from 'assets/images/google-play.png';
import AppleStore from 'assets/images/apple-store.png';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { usePromoterContext } from 'context/promoter/usePromoterContext';

import {
  Container,
  Conditions,
  FormFooter,
  Title,
  Text,
  Row,
  ImgFgts,
  Col,
  TitlePrimary,
  ContainerCaptcha,
} from './styles';
import { ISimulationResult } from './types';
import { TextCenter } from '../ProposalResult/styles';
import { A, ColSimple, TitlePrimaryV2 } from '../QuestionFgts1/styles';
import { Checkbox } from 'components/Form/Checkbox';

export const QuestionFgts2 = ({ setWizzardStep }: ISimulationResult) => {
  const { handleSimulate, create, setForm, form } = useSimulationContext();
  const history = useHistory();
  const { promoter } = usePromoterContext();
  const [nameBank, setNameBank] = useState('');
  const [nameBankCaixa, setNameBankCaixa] = useState('');
  const [nameBankCaixaAditional, setNameBankCaixaAditional] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [captcha, setCaptcha] = useState(false);
  const [activeAniversary, setActiveAniversary] = useState(false);
  const [activeBank, setActiveBank] = useState(false);
  const [btnBlock, setBtnBlock] = useState(true);

  useEffect(() => {
    switch (promoter?.promoterStyles.bank) {
      case '626':
        setNameBank('C6 BANK');
        setNameBankCaixa('C6 CONSIGNADO S.A.');
        break;
      case '149':
        setNameBank('FACTA FINANCEIRA');
        setNameBankCaixa('FACTA FINANCEIRA S.A.');
        break;
      case '329':
        setNameBank('ICRED');
        setNameBankCaixa('DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.');
        setNameBankCaixaAditional(`QI SOCIEDADE DE CRÉDITO DIRETO S.A.`);
        break;
      case '389':
        setNameBank('BANCO MERCANTIL');
        setNameBankCaixa('BANCO MERCANTIL DO BRASIL S.A.');
        break;
      case '243':
        setNameBank('BANCO MASTER');
        setNameBankCaixa('BANCO MASTER S.A');
        break;
      default:
        setNameBank('SAFRA');
        setNameBankCaixa('J. SAFRA S.A');
        break;
    }
  }, []);

  const setTokenCaptcha = async (tokenCaptcha: any) => {
    if (tokenCaptcha) {
      setCaptcha(true);
      setForm({
        ...form,
        token: tokenCaptcha,
      });

      if (activeAniversary && activeBank) {
        setBtnBlock(false);
      }
    }
  };

  const handleCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setBtnBlock(true);
    if (event.target.name === 'activeAniversary') {
      setActiveAniversary(event.target.checked);
      if (activeBank && event.target.checked && captcha) {
        setBtnBlock(false);
      }
    }
    if (event.target.name === 'activeBank') {
      setActiveBank(event.target.checked);
      if (activeAniversary && event.target.checked && captcha) {
        setBtnBlock(false);
      }
    }
  };

  const handleContinue = () => {
    setForm({ ...form });

    const data = {
      id: promoter?.promoterStyles.id as string,
      cpf: form.cpf,
      phone: form.phone,
      birthDate: form.birthDate,
      name: form.name,
      token: form.token ?? '',
    };

    handleSimulate(data);
    history.push('/innova-fgts/resultado-simulacao');
    setWizzardStep(2);
  };

  if (create.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center" noWrap>
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={26} fontWeight={700}>
              Realizando Simulação
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  const content = () => {
    return (
      <Container>
        <Conditions>
          <TextCenter>
            <ImgFgts
              src={Warning}
              style={{ marginRight: '5px', boxShadow: 'none', width: '140px' }}
            />
            <TitlePrimary>Falta pouco para você finalizar!</TitlePrimary>
            <TitlePrimaryV2>
              Você <strong>precisa concluir</strong> essa
            </TitlePrimaryV2>
            <TitlePrimaryV2>
              etapa para antecipar seu <strong>FGTS</strong>
            </TitlePrimaryV2>
            <Text style={{ fontSize: '14px', color: '#FE0000' }}>
              * Você precisa ter o aplicativo do FGTS
            </Text>
            <Text
              style={{
                fontSize: '22px',
                color: '#0AB4D1',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              Passo 1
            </Text>
            <Text style={{ fontSize: '22px', fontWeight: 'bold' }}>
              1. Baixe o aplicativo FGTS
            </Text>
            <Row style={{ margin: 0, border: 'none', padding: 0 }}>
              <ColSimple style={{ textAlign: 'right' }}>
                <A
                  href="https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador"
                  target="_blank"
                >
                  <ImgFgts
                    src={GooglePlay}
                    style={{ marginRight: '10px', width: '200px' }}
                  />
                </A>
              </ColSimple>
              <ColSimple style={{ textAlign: 'left' }}>
                <A
                  href="https://apps.apple.com/br/app/fgts/id1038441027"
                  target="_blank"
                >
                  <ImgFgts
                    src={AppleStore}
                    style={{ marginLeft: '10px', width: '200px' }}
                  />
                </A>
              </ColSimple>
            </Row>
          </TextCenter>
        </Conditions>
        <Conditions>
          <Row>
            <Col>
              <ImgFgts src={fgts2} />
            </Col>
            <Col>
              <Text
                style={{
                  fontSize: '22px',
                  color: '#0AB4D1',
                  fontWeight: 'bold',
                  marginTop: '20px',
                }}
              >
                Passo 2
              </Text>
              <Text style={{ fontSize: '17px' }}>
                <strong>{`2. Autorize a consulta do ${nameBankCaixa}`}</strong>
              </Text>
              <Text
                style={{ fontSize: '17px' }}
              >{`2.1. Autorize o(s) banco(s) abaixo a consultar seus dados`}</Text>
              <Text style={{ fontSize: '17px' }}>{`- ${nameBankCaixa}`}</Text>
              {nameBankCaixaAditional && (
                <>
                  <Text
                    style={{ fontSize: '17px' }}
                  >{`- ${nameBankCaixaAditional}`}</Text>
                  <Text
                    style={{ fontSize: '17px', color: 'red' }}
                  >{`É obrigatório os dois bancos estarem autorizados`}</Text>
                </>
              )}
            </Col>
          </Row>
          <Conditions>
            <Row style={{ display: 'block' }}>
              <Checkbox
                id="activeAniversary"
                name="activeAniversary"
                label="Já autorizei o Saque-Aniversário"
                onChange={handleCheck}
                margin="20px 0 0 0"
              />
              <Checkbox
                id="activeBank"
                name="activeBank"
                label="Já autorizei o Banco"
                onChange={handleCheck}
                margin="20px 0 0 0"
              />
            </Row>

            <ContainerCaptcha>
              <ReCAPTCHA
                sitekey="6LczAu4dAAAAANxkvoTPgkhuC7HfowUwvxZMO8dV"
                onChange={setTokenCaptcha}
              />
            </ContainerCaptcha>
          </Conditions>
        </Conditions>
        <FormFooter>
          <Button
            id="btn-step-8"
            type="button"
            label="Simular novamente"
            onClick={handleContinue}
            disabled={btnBlock}
          />
        </FormFooter>
        <FormFooter>
          <Button
            style={{ background: '#c2c2c2' }}
            id="btn-step-8-voltar"
            type="button"
            label="Sair"
            onClick={() => setWizzardStep(1)}
          />
        </FormFooter>
      </Container>
    );
  };

  return content();
};
