/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import SuccessImg from 'assets/images/success-v2.png';
import { Button } from 'components/Button';
import { Typography } from 'components/Typography';
import { WizzardSteps } from 'components/WizzardSteps';
import { Flex } from 'components/Flex';
import { ProgressBar } from 'components/ProgressBar';

import { ISimulationData } from 'context/simulation/types';
import { Container, FormFooter, ImgSuccess, TextCenter } from './styles';
import { ISimulationResult } from './types';

export const ProposalResult = ({ setWizzardStep, step }: ISimulationResult) => {
  const { createDigitation, setForm, form } = useSimulationContext();
  const { promoter } = usePromoterContext();
  const history = useHistory();

  const handleFinish = () => {
    history.push('/innova-fgts/sucesso');
    setWizzardStep(6);
  };

  const handleNewSimulation = () => {
    setForm({ id: '', cpf: '', phone: '' } as ISimulationData);
    history.push('/innova-fgts/simular-proposta');
    setWizzardStep(1);
  };

  if (createDigitation.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center">
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={26} fontWeight={700}>
              Enviando Proposta
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  if (createDigitation.status === 'error') {
    history.push('/innova-fgts/dados-bancarios');
    setWizzardStep(!promoter?.promoterStyles.usePix ? 5 : 9);
  }

  return (
    <Container>
      <WizzardSteps steps={6} step={step} />
      <TextCenter>
        <ImgSuccess src={SuccessImg} alt="bank" style={{marginTop: 30}} />
        <Typography
          id="msg-confirmacao"
          color="success"
          size={30}
          fontWeight={700}
        >
          <span style={{color: '#313b79'}}>PARABÉNS, {form.name.split(' ')[0]}!</span>
        </Typography>
        <Typography
          id="msg-confirmacao"
          color="success"
          size={30}
          fontWeight={400}
          marginTop={5}
        >
          <span style={{color: '#313b79'}}>SUA SOLICITAÇÃO DE</span>
        </Typography>
        <Typography
          id="msg-confirmacao"
          color="success"
          size={45}
          fontWeight={800}
          marginTop={20}
        >
          <span id="vlr-liberado" style={{color: '#00bfe9' }}>
            {form.valueSolicited}
          </span>
        </Typography>
        <Typography
          id="msg-confirmacao"
          color="success"
          size={30}
          fontWeight={800}
        ><span id="vlr-liberado" style={{ color: '#00bfe9' }}>
          REAIS
          </span>
        </Typography>
        <Typography
          id="msg-confirmacao"
          color="success"
          size={30}
          fontWeight={400}
          marginTop={20}
        >
          <span style={{color: '#313b79'}}>FOI ENCAMINHADA PARA O BANCO!</span>
        </Typography>
      </TextCenter>
      <FormFooter>
        <a
          style={{ width: '100%' }}
          href={createDigitation.data?.data.urlWhatsappBanco}
          target="_blank"
          rel="noopener noreferrer"
        >
          {promoter?.promoterStyles.bank === '074' ||
          promoter?.promoterStyles.bank === '389' ||
          promoter?.promoterStyles.bank === '329' ? (
            <TextCenter>
              <Typography color="default" size={20} fontWeight={500}>
                <span style={{color: '#3ece56'}}>
                  Em poucos instantes você receberá<br/>um sms para finalizar sua contratação.</span>
              </Typography>
            </TextCenter>
          ) : (
            <Button
              id="btn-formalizacao"
              style={{ background: '#17b068' }}
              type="button"
              label="PARA FINALIZAR CLIQUE AQUI PARA CONTINUAR COM A FORMALIZAÇÃO"
              onClick={handleFinish}
            />
          )}
        </a>
      </FormFooter>
      <FormFooter>
        <Button
          id="btn-step-6"
          style={{ background: '#c2c2c2' }}
          type="button"
          label="Nova simulação"
          onClick={handleNewSimulation}
        />
      </FormFooter>
    </Container>
  );
};
