import { Container } from './styles';
import { ITypography } from './types';

export const Typography = ({
  id,
  children,
  color,
  size,
  fontWeight,
  marginTop,
  style
}: ITypography) => {
  return (
    <Container id={id} color={color} size={size} fontWeight={fontWeight} marginTop={marginTop} style={style}>
      {children}
    </Container>
  );
};
